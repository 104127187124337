import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import {
  useDeleteNoteMutation,
  useGetNoteByIdQuery,
  useUpdateNoteMutation,
} from 'features/notes/notesApiSlice' // ノート用のクエリをインポート

import MarkDownLayout from 'components/MarkDownLayout'
import FlexBetween from 'components/FlexBetween'
import useAuth from 'hooks/useAuth'

const NoteDetail = () => {
  const { id } = useParams()
  const [note, setNote] = useState(undefined)

  const [open, setOpen] = useState(false)

  const [isShared, setIsShared] = useState(undefined)

  const { userId } = useAuth()

  const { data, isLoading, isError } = useGetNoteByIdQuery({ noteId: id }) // ノート用のクエリを使用

  const [
    updateNote,
    // {
    //   isLoading: isUpdating,
    //   isSuccess: isUpdateSuccess,
    //   isError: isUpdateError,
    // },
  ] = useUpdateNoteMutation()

  const [
    deleteNote,
    {
      isLoading: isDeleting,
      // isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      // error,
    },
  ] = useDeleteNoteMutation()

  const navigate = useNavigate()

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const deleteButtonHandler = async () => {
    await deleteNote(id)
    navigate(`/notes`)
  }

  useEffect(() => {
    if (data?.result) {
      if (data.result.userId?._id !== userId && !data.result.isShared) {
        navigate(`/notes`)
      }
      setNote(data.result)
      setIsShared(data.result.isShared || false)
    }
  }, [data, userId, navigate])

  const handleSwitchChange = async (event) => {
    setIsShared(event.target.checked)
    try {
      await updateNote({
        noteId: id,
        isShared: event.target.checked,
      }).unwrap()
    } catch (error) {
      console.log('error', error)
    }
  }

  if (isLoading || isShared === undefined) {
    return <Box m="20px">Loading...</Box>
  }

  if (isDeleting) {
    return <Box m="20px">Deleting...</Box>
  }

  if (isError) {
    return <Box m="20px">Error occurred while fetching note.</Box>
  }
  if (isDeleteError) {
    return <Box m="20px">Error occurred while deleting note.</Box>
  }

  return (
    <Box m="20px">
      {/* 質問 */}

      {note?.contentCategory === 'HUMAN_NOTE' ? (
        <Typography variant="h5" mt="10px">
          [タイトル]
        </Typography>
      ) : (
        <Typography variant="h5" mt="10px">
          [AI質問]
        </Typography>
      )}

      <MarkDownLayout markdown={note?.contentTitle} />
      <Typography variant="caption" mt="10px">
        カテゴリー
      </Typography>
      <Box>
        <Typography variant="alt">{note?.contentCategory}</Typography>
      </Box>

      <Box sx={{ mb: '0.5rem' }}>
        <Typography variant="caption">タグ</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {!!note?.tags &&
            note.tags.map((tag, index) => {
              const isLast = index === note.tags.length - 1
              const roleText = isLast ? tag : `${tag}, `
              return (
                <Typography variant="body1" key={tag}>
                  {roleText}
                </Typography>
              )
            })}
        </Box>
      </Box>

      <Divider sx={{ marginY: '1rem' }} />
      {/* 解答 */}
      {note?.contentCategory !== 'HUMAN_NOTE' ? (
        <Typography variant="h5" mt="10px">
          [AI解答]
        </Typography>
      ) : (
        <Typography variant="h5" mt="10px">
          [ノート]
        </Typography>
      )}
      <MarkDownLayout markdown={note?.content} />
      {/* アクションボタン */}
      <Divider sx={{ marginY: '1rem' }} />
      <FlexBetween>
        <FlexBetween>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isShared || false}
                  color="secondary"
                  onChange={handleSwitchChange}
                />
              }
              label={isShared ? '共有中' : '非公開'}
            />
          </FormGroup>

          <Typography variant="body2">共有ID: {note?.shareId}</Typography>
        </FlexBetween>
        <Button variant="contained" onClick={handleOpen}>
          <Typography variant="body2">削除</Typography>
        </Button>
      </FlexBetween>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ノート削除</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ノートの削除をしてもよろしいですか。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            キャンセル
          </Button>
          <Button color="warning" onClick={deleteButtonHandler} autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  ) // ノートの詳細を表示するコンポーネントに置き換えてください
}

export default NoteDetail
